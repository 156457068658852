<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="elevation-1">
            User Review Details
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
        <div style="background-color: #e57373">
          <v-card>
            <v-layout py-2 justify-start>
              <v-flex md3 sm3 xs3 lg3 xl3>
                <div v-viewer>
                  <v-avatar
                    style="cursor: pointer"
                    v-if="data.user.image"
                    size="150"
                  >
                    <img :src="baseURL + data.user.image" />
                  </v-avatar>
                </div>
              </v-flex>
              <v-flex md9 xl9 sm9 xs9 lg9>
                <h2 style="padding-top: 15px">
                  {{ data.user.firstname }} {{ data.user.lastname }}
                </h2>
                <br />
                <span>{{ data.user.username }}</span
                ><br />
                <span>{{ data.user.email }}</span>
              </v-flex>
            </v-layout>
          </v-card>
          <div style="padding-bottom: 20px"></div>
          <v-card class="mx-auto my-12" max-width="1000">
            <v-layout wrap pt-5 py-2 px-2>
              <v-flex>
                <v-avatar v-if="data.user.image" size="50">
                  <img :src="baseURL + data.user.image" />
                </v-avatar>
              </v-flex>
              <v-flex>
                <h5>{{ data.comment }}</h5>
              </v-flex>
              <v-flex>
                <v-rating
                  :value="parseFloat(data.rating)"
                  background-color="black"
                  color="black"
                  half-increments
                  small
                  readonly
                ></v-rating>
              </v-flex>
            </v-layout>
          </v-card>
          <div style="padding-bottom: 20px"></div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["userid"],
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      data: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/review/get",
        params: {
          id: this.userid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.data = response.data.data;
            // this.userStatus = this.user.status;
            // console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteuser() {
      axios({
        url: "/user/remove",
        method: "GET",
        params: {
          id: this.userid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>